.inviteMessage {
  margin: 10px 0 25px;
}

.buttonGroup {
  display: flex;
  gap: 20px;
}

.alert {
  margin-bottom: 20px;
}
